import React from 'react'
import Matze from '../assets/spacepowermatze.jpg'
import { Link } from 'react-router-dom';
import './Pt.css';

const PersonalTraining = () => {
  return (
    <div id='pt' className='w-full bg-white py-[9rem] px-4 md:grid-flow-row'>
        <div className='bild max-w[1240px] mx-auto flex md:grid-col-2'>

            <div className='grid flex-col justify-center'>
                <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold md:pl-4 pl-2'>Personal Training & Ernährungsberatung</h1>
                <p className='text-green-300 font-bold md:pl-4 pl-2 p-2 md:text-3xl sm:text-2xl'>Vor Ort oder auch Online ! </p>
                <p className='md:pl-4 pl-2 p-2 md:text-2xl sm:text-1xl'>
                  Ihr Weg zu einem gesünderen Lebensstil beginnt hier: Starten Sie noch heute Ihre Reise zu einem fitteren und gesünderen Leben. Unsere erstklassigen Personal Trainer sind bereit, 
                                Sie auf Ihrem Weg zu begleiten. Kontaktieren Sie uns, um mehr über unsere Angebote im Bereich Personal Training und Ernährungsberatung zu erfahren.
                </p>
                <p className='text-green-300 font-bold md:pl-4 pl-2 p-2 md:text-3xl sm:text-2xl'>Vom Mr.Fitness Germany - Matthias Krenkler</p>
                <button className='bg-[#00df9a] w-[200px] rounded-md my-6 mx-auto py-3 text-black font-bold'><Link to='tel: 071416856067'>Tel.: 07141 68 56 067</Link></button>
                <button className='bg-[#00df9a] w-[200px] rounded-md  mx-auto py-3 text-black font-bold'><Link to='tel: 0173 36 04 737'>Mobil: 0173 36 04 737</Link></button>
                
    
            </div>
            <div className='bild2'>
            <img className='p-3 mx-auto my-4' src={Matze} alt="/" /> 
          </div>
        </div>

    </div>
  )
}

export default PersonalTraining