import React from 'react'
import Single from '../assets/single.png'
import Double from '../assets/double.png'
import Free from '../assets/Free.png'

const Membership = () => {
  return (
   
    <div id='mt' className='w-full pb-24 px-4 bg-black'>
    <div className='justify-center text-white text-center md:text-5xl sm:text-4xl text-3xl font-bold md:pl-4 pl-2 py-[9rem]'>
        <h1>Mitgliedschaft</h1>
    </div>
      
    <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
      <div className='bg-white w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500'>
        <img className='w-20 mx-auto mt-[-3rem] bg-white rounded' src={Single} alt='' />
        <h2 className='text-2xl font-bold text-center py-8'>Einzel Vertrag</h2>
        <p className='text-center text-4xl font-bold'>33€ / Monat</p>
        <div>
          <p className='py-2 border-b mx-8 mt-8'>Einmalige Vereinsaufnahme 33€</p>
          <p className='py-2 border-b mx-8'>Keine Versteckten Kosten!</p>
          <p className='py-2 border-b mx-8'>Quartalsweise kündbar</p>
        </div>
        <div className='bg-[#00df9a] w-[200px] rounded-md my-6 mx-auto py-3 text-black font-bold text-center'>
                Komm in Club!
        </div>
      </div>

      <div className='bg-white w-full shadow-xl flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-500'>
        <img className='w-20 mx-auto mt-[-3rem] bg-white rounded' src={Double} alt='' />
        <h2 className='text-2xl font-bold text-center py-8'>Partner Vertrag</h2>
        <p className='text-center text-4xl font-bold'>60 € / Monat</p>
        <div>
        <p className='py-2 border-b mx-8 mt-8'>Einmalige Vereinsaufnahme 60€</p>
          <p className='py-2 border-b mx-8'>Keine versteckten Kosten!</p>
          <p className='py-2 border-b mx-8'>Quartalsweise kündbar</p>
        </div>
        <div className='bg-[#00df9a] w-[200px] rounded-md my-6 mx-auto py-3 text-black font-bold text-center'>
                Komm in Club!
        </div>
      </div>

      <div className='bg-white w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-500'>
        <img className='w-20 mx-auto mt-[-3rem] bg-white rounded' src={Free} alt='' />
        <h2 className='text-2xl font-bold text-center py-8'>Probetraining</h2>
        <p className='text-center text-4xl font-bold'>Free</p>
        <div>
          <p className='py-2 border-b mx-8 mt-8 pb-7'></p>
          <p className='py-2 border-b mx-8 pb-7'></p>
          <p className='py-2 border-b mx-8 pb-7'></p>
        </div>
        <div className='bg-[#00df9a] w-[200px] rounded-md my-6 mx-auto py-3 text-black font-bold text-center'>
                Komm in Club!
        </div>
      </div>
    </div>
    </div>
  )
}

export default Membership