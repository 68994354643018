import React, { useState } from 'react'
import Typed from 'react-typed'
import './Hero.css'
import {BsChevronCompactLeft, BsChevronCompactRight} from 'react-icons/bs'
import {RxDotFilled} from 'react-icons/rx'

const Hero = () => {

  const slides = [
    {
      url: 'https://i.ibb.co/4TmX0vp/spacepowerbereich-darker.jpg'
    },
    {
      url: 'https://i.ibb.co/GVKL5JP/spacepowergym-background-darker.jpg'
    },
    {
      url: 'https://i.ibb.co/1nSGvvK/spacepower1.jpg'
    },
    {
      url: 'https://i.ibb.co/Chcp52C/spacepower2.jpg'
    },
    {
      url: 'https://i.ibb.co/Lk2XQnn/spacepower3.jpg'
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0)

  const prevSlide = () => {
    const isFirstSLide = currentIndex === 0;
    const newIndex = isFirstSLide ? slides.length -1 : currentIndex;
    setCurrentIndex(newIndex);
  }

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length -1;
    const newIndex = isLastSlide ? 0 : currentIndex +1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (

    <div className='mt-[-96px] m:mt-3 w-full h-screen mx-auto text-center flex flex-col justify-center '>
        
      <div className='bg-black max-w-[1400px] h-[70%] w-full m-auto md:py-13 px-4 relative group'>
        <h1 className='heroes md:text-5xl sm:text-4xl text-2xl font-bold md:py6 uppercase p-1'>
              home for the super heroes
        </h1>
        <div className='justify-center items-center'>
                <Typed 
                className='md:text-5xl text-4xl font-bold md:pl-4 pl-2 text-white'
                strings={['train','bar','meet','repeat']} typedSpeed={2000} backSpeed={200} onStart={200} loop/>
        </div>
            <p className='md:text-3xl text-2xl font-bold text-gray-500 p-3'>Öffnungszeiten: Mo.-Fr. 10-22 Uhr / Sa.-So. 12-20 Uhr</p>
        <div 
          style={{backgroundImage: `url(${slides[currentIndex].url})`}} 
          className='w-full h-[22rem] 2xl:h-[31rem] xl:h-[30rem] lg:h-[29rem] md:h-[29rem] sm:h-[30rem] rounded-2xl bg-center bg-cover duration-500 mb-2'>
        </div>
        
          {/*Left Arrow*/}
        <div className='hidden group-hover:block absolute top-[65%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
          <BsChevronCompactLeft onClick={prevSlide} size={30} />
        </div>
          {/*Right Arrow*/}
        <div className='hidden group-hover:block absolute top-[65%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
          <BsChevronCompactRight onClick={nextSlide} size={30} />
        </div>
          <div className='flex top-4 justify-center py-3 text-white'>
            {slides.map((slide, slideIndex) => (
              <div key={slideIndex} onClick={() => goToSlide(slideIndex)} className='text-2xl cursor-pointer'>
                <RxDotFilled />
              </div>
            ))}
          </div>
        </div>
      </div>
       
  );
}

export default Hero