import React, {useState} from 'react'
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'
import {Link} from 'react-scroll'
import './Navbar.css'
import logo from '../assets/logo.png';


const Navbar = () => {
    const [nav, setNav] = useState(false)

    const handleNav = () => {
        setNav(!nav)

    }

  return (

    <div className='flex justify-between items-center h-24 max-w-[1350px] mx-auto text-white z-[9999] overflow-hidden'> 
        <div className='logo1 w-40 pl-18'> <img src={logo} alt="Logo" /> </div>
       
        <h1 className='logo w-full text-3xl font-bold m-4 uppercase cursor-pointer'>space power gym</h1>
        <ul className='hidden md:flex gap-5'> 
            <li className='nav__link cursor-pointer'><Link to="location" spy={true} smooth={true} offset={50} duration={500} >Standort</Link></li>
            <li className='nav__link cursor-pointer'><Link to="mt" spy={true} smooth={true} offset={50} duration={500} >Mitgliedschaft</Link></li>
            <li className='nav__link cursor-pointer'><Link to="pt" spy={true} smooth={true} offset={50} duration={500} >PersonalTraining</Link></li>
            <li className='nav__link cursor-pointer'><a href='https://spacepower.store/'>Shop</a></li>
        </ul>
        <div onClick={handleNav} className='block md:hidden fixed overflow-hidden pl-[90%] z-[9999] text-gray-400'>
            {nav ? <AiOutlineClose size={30}/> : <AiOutlineMenu size={30}/>}
            
        </div>
        <div className={nav ? 'fixed left-0 z-[9999] top-0 w-[60%] h-full border-r border-r-gray-900 bg-gray-900 ease-in-out duration-500' : 'fixed left-[-100%]'}>
            <h1 className='w-full text-3xl font-bold text-silver m-4 cursor-pointer'>Space Power Gym</h1>
            <ul className='uppercase p-4'>
                <li className='p-4 border-b border-gray-800 hover:text-gray-300 cursor-pointer'><Link to="location" spy={true} smooth={true} offset={50} duration={500} >Standort</Link></li>
                <li className='p-4 border-b border-gray-800 hover:text-gray-300 cursor-pointer'><Link to="mt" spy={true} smooth={true} offset={50} duration={500} >Mitgliedschaft</Link></li>
                <li className='p-4 border-b border-gray-800 hover:text-gray-300 cursor-pointer'><Link to="pt" spy={true} smooth={true} offset={50} duration={500} >PersonalTraining</Link></li>
                <li className='p-4 border-b border-gray-800 hover:text-gray-300 cursor-pointer'><a href='https://spacepower.store/'>Shop</a></li>
            </ul>
        </div>
    </div>    
  );
};

export default Navbar