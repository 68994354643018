import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import PersonalTraining from './components/PersonalTraining';
import Location from './components/Location';
import Membership from './components/Membership';
import Footer from './components/Footer';


const App = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Location />
      <Membership />
      <PersonalTraining />
      <Footer />
    </>

  );
}

export default App;
