/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'


const Location = () => {
 
  return (
    <div id='location' className='bg-white w-full py-[9rem] text-black px-4'>
        <div className='max-w-[1240px] mx-auto grid lg:grid-cols-3'>
            <div className='lg:col-span-2 my-4'>
                <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold md:pl-4 pl-2 p-2'>Location</h1>
                <p className='md:text-2xl sm:text-1xl font-bold md:pl-4 pl-2'>Neckartalstrasse 51, 71642 Ludwigsburg</p>

            </div>
            <div>
            <iframe className='2xl:w-[30rem] 2xl:h-[20rem] xl:w-[20rem] xl:h-[20rem]' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41962.61516522765!2d9.167999341262153!3d48.902750812388994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4799ce043c40fceb%3A0x7bddc7cae776e92e!2sGold%20Gym%20Bodybuilding%20Team%20e.V.%20%2F%2F%20spacepowergym!5e0!3m2!1sen!2sde!4v1691690117802!5m2!1sen!2sde"></iframe> 
            </div>
        </div>
    </div>
  );
};



export default Location