import React from 'react'
import { Link } from 'react-router-dom';
import {
    FaInstagramSquare,
    FaFacebookSquare,
} from'react-icons/fa'

const Footer = () => {
  return (
    <div className='max-w-[1400px] w-full m-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
        <div className='lg:col-span-3 grid  w-full m-auto '>
          <div>
            <h1 className='mr-20 w-full m-auto text-3xl font-bold text-[#00df9a] m-4'>SpacePowerGym / GoldGym e.V.</h1>
          </div>
            
              <div className='flex justify-center space-x-20 md:w-[75] my-6'>
                  
                  
                  <Link to='https://www.facebook.com/spacepowergym/?locale=de_DE' className="hover:text-[#00df9a]">< FaFacebookSquare size={50}/></Link>
                  <Link to='https://www.instagram.com/spacepowergym_goldgymev/?hl=de' className="hover:text-[#00df9a]"><FaInstagramSquare size={50}/></Link>
              </div>
            <div className='flex justify-center space-x-20 md:w-[75] my-6'>
                <h1 className='hover:text-[#00df9a]'><Link to='tel: 0173 36 04 737'>Kontakt</Link></h1>
                <h1>Jobs</h1>
            </div>
            </div>
    </div>
  )
}

export default Footer